import {
    toasts,
    loading,
    announcement_reducers,
    challenge_reducers,
    curriculum_reducers,
    district_reducers,
    lesson_reducers,
    response_reducers,
    school_reducers,
    stream_reducers,
    user_reducers
} from "mindsets-js-sdk";
import autocomplete_reducers from "mindsets-js-sdk/src/react/components/Autocomplete/autocomplete_reducers";
import challenge_reducers_2 from "./challenge_reducers";
import district_reducers_2 from "./district_reducers";
import history_reducers from "./history_reducers";
import lesson_reducers_2 from "./lesson_reducers";
import pam_reducers from "./pam_reducers";
import performance_reducers from "./performance_reducers";
import stream_reducers2 from "./stream_reducers";

export default Object.assign(
    {toasts, loading},
    announcement_reducers,
    challenge_reducers,
    challenge_reducers_2,
    curriculum_reducers,
    district_reducers,
    district_reducers_2,
    history_reducers,
    lesson_reducers,
    lesson_reducers_2,
    pam_reducers,
    performance_reducers,
    response_reducers,
    school_reducers,
    stream_reducers,
    stream_reducers2,
    user_reducers,
    {
        autocomplete_items: autocomplete_reducers.autocomplete_items,
        autocomplete_totals: autocomplete_reducers.autocomplete_totals,
    }
);
